// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60061: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text2Neutral1 },
      colorTypes: {
        text2,
        shadow,
        border1,
        background4,
        background3,
        background5,
      },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: 'transparent',
        color: text2,
        background: shadow,
        '&::placeholder': {
          color: border1,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background3} inset !important`,
          borderColor: border1,
          '-webkit-text-fill-color': `${text2} !important`,
        },
        '@media (pointer: coarse)': {
          '&:-webkit-autofill': {
            border: '0px',
          },
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        border: `3px solid ${background5}`,
        borderRadius: borderRadius.md,
        ...text2Neutral1,
        '&::placeholder': {
          color: background4,
        },
        '@media (pointer: coarse)': {
          border: '0px',
        },
      },
    },
  };
};
