// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60061,
  JSSThemeVariables60061,
  getJSSThemePatterns60061,
} from './JSSTheme60061';
import { GeoblockPage60061 } from './GeoblockPage60061';

// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60061')).FullDesktop60061,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile60061')).FullMobile60061,
  }),
  JSSTheme: JSSTheme60061,
  JSSThemeVariables: JSSThemeVariables60061,
  JSSThemePatterns: getJSSThemePatterns60061,
  GeoblockPage: GeoblockPage60061,
});
