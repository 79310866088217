// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from '../58-tornadobet/JSSThemeBetslip58';
import { JSSThemeHeaderMenu58 } from '../58-tornadobet/JSSThemeHeaderMenu58';
import { JSSThemeMyBets58 } from '../58-tornadobet/JSSThemeMyBets58';
import { JSSThemeBannerItems58 } from '../58-tornadobet/JSSThemeBannerItems58';
import { JSSThemeSportsMenu58 } from '../58-tornadobet/JSSThemeSportsMenu58';
import { JSSThemeForm60061 } from './JSSThemeForm60061';
import { JSSThemeTooltip58 } from '../58-tornadobet/JSSThemeTooltip58';
import { JSSThemeBonuses58 } from '../58-tornadobet/JSSThemeBonuses58';
import { JSSThemeHeaderMobile58 } from '../58-tornadobet/JSSThemeHeaderMobile58';
import { JSSThemeSearch58 } from '../58-tornadobet/JSSThemeSearch58';
import { JSSThemeEventSlider58 } from '../58-tornadobet/JSSThemeEventSlider58';
import { JSSThemeOutcomes58 } from '../58-tornadobet/JSSThemeOutcomes58';
import { JSSThemeCoupons58 } from '../58-tornadobet/JSSThemeCoupons58';
import { JSSThemeHeader58 } from '../58-tornadobet/JSSThemeHeader58';
import { JSSThemeBalance58 } from '../58-tornadobet/JSSThemeBalance58';
import { JSSThemeAuthForm58 } from '../58-tornadobet/JSSThemeAuthForm58';
import { JSSThemePopularLeagues58 } from '../58-tornadobet/JSSThemePopularLeagues58';
import { JSSThemeAccountSection58 } from '../58-tornadobet/JSSThemeAccountSection58';
import { JSSTheme58Resets } from '../58-tornadobet/JSSTheme58Resets';
import { JSSThemeLive58 } from '../58-tornadobet/JSSThemeLive58';
import { JSSThemeCasino58 } from '../58-tornadobet/JSSThemeCasino58';
import { JSSThemePromotions58 } from '../58-tornadobet/JSSThemePromotions58';
import { JSSThemeSitemap58 } from '../58-tornadobet/JSSThemeSitemap58';
import { JSSThemeBetgenius58 } from '../58-tornadobet/JSSThemeBetgenius58';
import { JSSThemeAffiliates60061 } from './JSSThemeAffiliates60061';

export const colorTypes58: ColorTypes = {
  ...colorTypes,
  primary: '#bb0303',
  text1: '#494949',
  text2: '#bfbfbf',
  text3: '#203552',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '',
  background1: '#091623',
  background2: '#958b85',
  background3: '#112840',
  background4: '#999',
  background5: '#2B456B',
  background6: '#2B456B', //new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#830202',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#830202',
  neutral1: '#2B456B',
  neutral2: '#5c0101',
  neutral3: '',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#0d1f2d',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations58 = getColorCombinations(colorTypes58, pick);

export const JSSThemeVariables60061: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes58,
    colorCombinations: colorCombinations58,
  },
  fonts: {
    default: {
      name: 'osg-ubuntu',
      fontFamily: 'osg-ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'ubuntu/ubuntu-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'ubuntu/ubuntu-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme60061: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteBg1,
        whiteNeutral1,
        whiteAccent,
        whitePrimary,
        text4,
      },
      colorTypes: {
        background1,
        background3,
        primary,
        text2,
        text1,
        black,
        white,
        neutral1,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },
    mybets: JSSThemeMyBets58(themeVars),
    header: JSSThemeHeader58(themeVars),
    affiliates: JSSThemeAffiliates60061(themeVars),
    headerMenu: JSSThemeHeaderMenu58(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: getButtonColors(whiteNeutral1),
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(whiteNeutral1),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text1,
          },
        }),
      },
    },
    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: background3,
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: white,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: white,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: neutral1,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: text2,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        ...whiteBg1,
        fontSize: fontSizes.sm,
        margin: 0,
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: '#0d1c2c',
      },
      rootGeoblock: {
        background: '#132133',
      },
    },
    banners: JSSThemeBannerItems58(themeVars),
    sportsMenu: JSSThemeSportsMenu58(themeVars),
    form: JSSThemeForm60061(themeVars),
    tooltip: JSSThemeTooltip58(themeVars),
    bonuses: JSSThemeBonuses58(themeVars),
    headerMobile: JSSThemeHeaderMobile58(themeVars),
    search: JSSThemeSearch58(themeVars),
    eventSlider: JSSThemeEventSlider58(themeVars),
    outcomes: JSSThemeOutcomes58(themeVars),
    coupons: JSSThemeCoupons58(themeVars),
    balance: JSSThemeBalance58(themeVars),
    live: JSSThemeLive58(themeVars),
    authForm: JSSThemeAuthForm58(themeVars),
    popularLeagues: JSSThemePopularLeagues58(themeVars),
    accountSection: JSSThemeAccountSection58(themeVars),
    promotions: JSSThemePromotions58(themeVars),
    sitemap: JSSThemeSitemap58(themeVars),
    resets: JSSTheme58Resets(themeVars),
    casino: JSSThemeCasino58(themeVars),
    betgenius: JSSThemeBetgenius58(themeVars),
    PageNotFound: {
      pageNotFoundRoot: {
        color: white,
        background:
          'url(https://cdn.tornadoaffiliates.com/cms/uploads/default/tornado-bg.png)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
  };
};

export const getJSSThemePatterns60061: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { text2Bg2, whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
